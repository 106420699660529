<template>
	<div class="px-3 pl-md-0 pr-md-6 pt-6 pb-6">
		<v-row>
			<v-col cols="12" md="6">
				<v-card class="card py-6 px-6">
					<v-toolbar color="transparent" elevation="0" class="px-0">
						<span class="text-normal text-offwhite">Most recent polls</span>
						<v-spacer></v-spacer>
						<v-btn elevation="0" class="text-dark" rounded color="systemwhite" to="/polls/create" :disabled="!connected">CREATE POLL</v-btn>
					</v-toolbar>
					<v-card-text class="px-0">
						
						<SubjectCard v-for="(poll, i) in polls" :key="i" :data="{ title: poll.title, desc: poll.data.question, slug: poll.reference, datetime: poll.enddate, votes: poll.awnser_count }" />
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="6">
				<v-row>
					<v-col cols="12" md="6">
						<v-card class="px-6 pb-6 pt-3" >
							<v-toolbar color="transparent" elevation="0" class="px-0">
								<span class="text-paragraph">Claim Share Balance</span>
								<v-spacer></v-spacer>
								<span class="text-paragraph opacity-60">USD</span>
							</v-toolbar>
							<v-card-text class="px-0"><span class="text-card-number">{{claimbalanceETH}} ETH</span> </v-card-text>
							<v-card-actions class="px-0"><v-btn outlined rounded to="/sacrifice/withdraw">WITHDRAW BALANCE</v-btn></v-card-actions>
						</v-card>
					</v-col>
					<v-col cols="12" md="6" >
						<v-card class="px-6 pb-6 pt-3">
							<v-toolbar color="transparent" elevation="0" class="px-0">
								<span class="text-paragraph">Sacrificed Tokens Points</span>
								<v-spacer></v-spacer>
								<span class="text-paragraph opacity-60">SP</span>
							</v-toolbar>
							<v-card-text class="px-0"><span class="text-card-number">{{ balance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span> </v-card-text>
							<v-card-actions class="px-0"><v-btn to="/sacrifice" outlined rounded>SACRIFICE TOKENS</v-btn></v-card-actions>
						</v-card>
					</v-col>
					<v-col cols="12">
						<v-card class="px-6 py-6">
							<v-row>
								<v-col cols="12">
									<p class="text-normal text-offwhite">Market Protection & Stability</p>
									<span class="text-paragraph text-offwhite">Rest easy with Anti-Dump and Price Stability Mechanisms integrated into the Dapp.</span>
								</v-col>
								<v-col cols="12" class="d-flex">
									<v-btn outlined rounded class="align-self-center mx-auto" href="https://crypto-strikers.com/#token/9" target="_blank">LEARN HOW IT WORKS</v-btn>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import SubjectCard from "../components/Subject.vue"
import axios from 'axios';
export default {
	components: {
		SubjectCard
	},
	props: {
		connected: Boolean,
		balance: Number,
		claimbalanceETH: Number,

	},
	data: () => ({
		ready: true,
		polls: []
	}),
	mounted() {
		axios.get("https://api.crypto-strikers.com/v1/voting/recent").then((res) => {
			this.polls = res.data.data;
		})
		
	}
}
</script>